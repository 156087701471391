export const fonctionOptions = [
  {
    id: "1",
    text: "Distribution des médicaments",
    value: "distribution des médicaments",
  },
  {
    id: "2",
    text: "Transport des vacanciers",
    value: "transport des vacanciers",
  },
  { id: "3", text: "Restauration", value: "restauration" },
  { id: "4", text: "Entretien des locaux", value: "entretien des locaux" },
  { id: "5", text: "Activités spécifiques", value: "activités spécifiques" },
  { id: "6", text: "Autre", value: "autre" },
];
