import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45eig_45global from "/app/packages/frontend-bo/src/middleware/redirect-eig.global.js";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45eig_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "check-role": () => import("/app/packages/frontend-bo/src/middleware/check-role.js"),
  "is-connected": () => import("/app/packages/frontend-bo/src/middleware/is-connected.js"),
  "redirect-on-hebergements": () => import("/app/packages/frontend-bo/src/middleware/redirect-on-hebergements.js")
}